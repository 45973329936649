<template>
  <div class="contact-us">
    <div class="container">
      <div class="contact-description">
        <div class="contact-title">
          {{ $t('contact-component.title') }}
        </div>
        <div class="contact-description__info">
          <div class="city">{{ $t('contact-component.city') }}</div>
          <div class="contacts">
            <!--<div class="phone">
          <div class="contact-item-title">{{$t('menu.call') }}:</div>
          <a class="contact__link" href="#"> </a>
        </div>-->
            <div class="email">
              <div class="contact-item-title">{{ $t('write') }}:</div>
              <a class="contact__link" href="mailto:dive@tpu.ru">dive@tpu.ru</a>
            </div>
            <div class="socials">
              <div class="contact-item-title">{{ $t('we-socials') }}:</div>
              <SocialNetworks />
            </div>
          </div>
        </div>
      </div>

      <div class="contact-form">
        <div class="contact-form-title">
          {{ $t('contact-component.form-title') }}
        </div>
        <div class="form__wrapper">
          <div id="b24-form" />
          <!--          <form @submit.prevent="sentEmail">-->
          <!--            <div class="form-group">-->
          <!--              <input-->
          <!--                v-model="emailData.phone"-->
          <!--                class="only-line-text"-->
          <!--                :class="{ error: !isPhoneValid }"-->
          <!--                type="text"-->
          <!--                required-->
          <!--                :placeholder="$t('contact-component.input-phone')"-->
          <!--                name="phone"-->
          <!--                @input="resetError('isPhoneValid')"-->
          <!--              />-->
          <!--              <span v-show="!isPhoneValid" class="only-line-text-error-message">-->
          <!--                Введите корректный номер телефона</span-->
          <!--              >-->
          <!--            </div>-->
          <!--            <div class="form-group">-->
          <!--              <input-->
          <!--                v-model="emailData.email"-->
          <!--                class="only-line-text"-->
          <!--                :class="{ error: !isEmailValid }"-->
          <!--                type="text"-->
          <!--                required-->
          <!--                :placeholder="$t('contact-component.input-email')"-->
          <!--                name="email"-->
          <!--                @input="resetError('isEmailValid')"-->
          <!--              />-->
          <!--              <span v-show="!isEmailValid" class="only-line-text-error-message">-->
          <!--                Введите корректный E-mail адрес</span-->
          <!--              >-->
          <!--            </div>-->
          <!--            <div class="form-group">-->
          <!--              <input-->
          <!--                v-model="emailData.message"-->
          <!--                class="only-line-text"-->
          <!--                :class="{ error: !isMessageValid }"-->
          <!--                type="text"-->
          <!--                required-->
          <!--                :placeholder="$t('contact-component.input-message')"-->
          <!--                name="message"-->
          <!--                @input="resetError('isMessageValid')"-->
          <!--              />-->
          <!--              <span-->
          <!--                v-show="!isMessageValid"-->
          <!--                class="only-line-text-error-message"-->
          <!--              >-->
          <!--                Сообщение не может быть больше 255 знаков</span-->
          <!--              >-->
          <!--            </div>-->
          <!--            <div class="sent-wrapper">-->
          <!--              <button class="btn btn-common">{{ $t('make-request') }}</button>-->
          <!--            </div>-->
          <!--          </form>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialNetworks from '@/shared/ui/SocialNetworks.vue';

export default {
  name: 'ContactUsComponent',
  components: { SocialNetworks },
  data() {
    return {
      emailData: {
        email: '',
        message: '',
        phone: '',
        subject: 'Сообщение с сайта',
      },
      isPhoneValid: true,
      isEmailValid: true,
      isMessageValid: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.set_b24_form();
    }, 770);
  },
  beforeUnmount() {
    document.getElementById('b24-form').innerHTML = '';
  },
  methods: {
    set_b24_form() {
      const s = document.createElement('script');
      s.setAttribute('data-b24-form', 'inline/7/7iwnkc');
      s.setAttribute('type', 'text/javascript');
      s.setAttribute('data-skip-moving', 'true');
      s.innerHTML = `(function (w, d, u) {
              var s = d.createElement('script');
              s.async = true;
              s.src = u + '?' + ((Date.now() / 180000) | 0);
              var h = d.getElementsByTagName('script')[0];
              h.parentNode.insertBefore(s, h);
            })(
              window,
              document,
              'https://cdn-ru.bitrix24.ru/b27235322/crm/form/loader_7.js',
            );`;
      document.getElementById('b24-form').appendChild(s);
    },
    validateForm() {
      const phoneRegex =
        /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
      const emailRegex =
        /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
      this.isPhoneValid = phoneRegex.test(this.emailData.phone);
      this.isEmailValid = emailRegex.test(this.emailData.email);
      this.isMessageValid =
        this.emailData.message !== '' && this.emailData.message.length <= 255;
      return this.isPhoneValid && this.isEmailValid && this.isMessageValid;
    },
    resetError(name) {
      this.openModal();

      this[name] = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-us {
  padding-bottom: 150px;
  padding-top: 90px;
  display: flex;
  .container {
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
      padding: 0 10px;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-content: center;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }
  .contact-description {
    display: flex;
    flex-direction: column;
    max-width: 388px;
    @media screen and (max-width: 1024px) {
      margin: auto;
    }
    @media screen and (max-width: 500px) {
      padding: 0 10px;
      max-width: 300px;
    }
    .contact-title {
      font-family: 'Proxima Nova', sans-serif;
      font-size: 36px;
      line-height: 130%;
      letter-spacing: 0.02px;
      color: #222e37;
      font-weight: 600;
    }
    .contact-description__info {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 50px;
      .city {
        font-family: 'Raleway', sans-serif;
        letter-spacing: 1px;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 27px;
        color: #222e37;
        opacity: 0.7;
      }
      .contacts {
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        .contact-item-title {
          font-family: 'Proxima Nova', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 27px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #222e37;
        }
        .socials,
        .email {
          .contact-item-title {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .contact-form {
    max-width: 560px;
    @media screen and (max-width: 1024px) {
      padding-top: 30px;
      text-align: center;
      margin: auto;
    }
    @media screen and (max-width: 500px) {
      max-width: 300px;
      margin: auto;
    }
    .contact-form-title {
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      color: #222e37;
    }
    .form__wrapper {
      .sent-wrapper {
        display: flex;
        max-width: 250px;
        width: 100%;
        @media screen and (max-width: 1024px) {
          margin: auto;
          justify-content: center;
        }
      }
      .form-group {
        width: 100%;
        padding-bottom: 33px;
      }
    }
  }
}
</style>
