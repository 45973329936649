import { defineStore } from 'pinia';
import axiosInstance from '../axios';

export const useEmailFormStore = defineStore('emailFormStore', {
  state: () => ({
    titleClosed: null,
    titleOpened: null,
    buttonText: null,
  }),
  actions: {
    async fetchEmailForm(reset = false) {
      if (!reset) {
        this.titleClosed = null;
        this.titleOpened = null;
        this.buttonText = null;
      }

      try {
        const response = await axiosInstance.get('/lms/application-cost-form');
        const data = response.data.data;

        if (response.data.success) {
          this.titleClosed = data.title_closed;
          this.titleOpened = data.title_opened;
          this.buttonText = data.button;
        }
      } catch {
        console.error('Ошибка получения данных.');
      }
    },
    reset() {
      this.fetchEmailForm(true);
    },
  },
});
