import { defineStore } from 'pinia';
import axiosInstance from '../axios';

export const useNextSimulatorStore = defineStore('nextSimulatorStore', {
  state: () => ({
    currentID: null,
    nextSimulatorID: null,
    nextSimulatorTitle: null,
    loading: false,
  }),
  actions: {
    async fetchNextSimulator(reset = false) {
      if (!reset) {
        this.nextSimulatorID = null;
        this.nextSimulatorTitle = null;
      }
      const params = { id: this.currentID };
      try {
        this.loading = true;
        const response = await axiosInstance.get('/lms/next', { params });
        const data = response.data.data;
        if (response.data.success) {
          this.nextSimulatorID = data.id;
          this.nextSimulatorTitle = data.title;
          this.loading = false;
        }
      } catch {
        console.error('Ошибка при получении данных.');
      }
    },
    setCurrentNextID(id) {
      this.currentID = id;
    },
    reset() {
      this.fetchNextSimulator(true);
    },
  },
});
