import { defineStore } from 'pinia';
import axiosInstance from '../axios';

export const useSimulatorStore = defineStore('simulatorStore', {
  state: () => ({
    curID: null,
    item: null, //полные данные о тренажере
    background: null,
    header: null,
    title: null,
    goal: null,
    tasks: [],
    headsets: [],
    tools: [],
    safety: null,
    challenges: [],
    gallery: [],
    video: null,
    advisors: [],
    results: null,
    loading: false,
  }),
  actions: {
    async fetchSimulator(reset = false) {
      if (!reset) {
        this.item = null;
        this.background = null;
        this.header = null;
        this.title = null;
        this.goal = null;
        this.tasks = [];
        this.headsets = [];
        this.tools = [];
        this.safety = null;
        this.challenges = [];
        this.gallery = [];
        this.video = null;
        this.advisors = [];
        this.results = null;
        this.loading = false;
      }
      this.loading = true;
      const response = await axiosInstance.get(
        `/lms/application/${this.curID}`,
      );
      const data = response.data.data;

      this.item = data;
      this.background = this.item.background;
      this.header = {
        title: this.item.title,
        category: this.item.category,
        subtitle: this.item.subtitle,
      };
      this.title = this.item.title;
      this.goal = this.item.goal;
      this.tasks = this.item.tasks;
      this.headsets = this.item.headsets;
      this.tools = this.item.tools;
      this.safety = this.item.safety;
      this.challenges = this.item.challenges;
      this.gallery = this.item.gallery;
      this.video = this.item.video;
      this.advisors = this.item.advisors;
      this.results = {
        result: this.item.result,
        development_time: this.item.development_time,
        square: this.item.square,
        virtual_objects: this.item.virtual_objects,
      };
      this.loading = false;
    },
    setCurrentID(id) {
      this.curID = id;
    },
    reset() {
      this.fetchSimulator(true);
    },
  },
});
