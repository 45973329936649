<template>
  <button
    v-if="visible"
    :style="{
      opacity: opacity,
      visibility: visible ? 'visible' : 'hidden',
      transition: 'opacity 0.3s',
    }"
    class="scroll-to-top"
    @click="scrollToTop"
  >
    <div class="totop"></div>
  </button>
</template>

<script>
import { to_top_window } from '@/shared/lib/window_navigation';

export default {
  data() {
    return {
      visible: false,
      opacity: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll, { passive: true });
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset;
      const maxScroll =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = scrollTop / maxScroll;

      if (scrollPercent > 0.4) {
        this.visible = true;
        // Прозрачность меняется от 0 до 1, начиная с 40% и достигая полной видимости при 100% прокрутки
        this.opacity = Math.min((scrollPercent - 0.4) * 1.67, 1);
      } else {
        this.visible = false;
        this.opacity = 0;
      }
    },
    scrollToTop() {
      to_top_window();
    },
  },
};
</script>

<style scoped lang="scss">
.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
</style>
